<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <router-link :to="{ name: 'admin.globals.add' }" class="btn btn-add" v-if="!isLoading && checkPermissions(['general.view'])">
          <span>{{ 'Globale toevoegen' }}</span></router-link>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane" :class="{'loading': isLoading}">
        <div class="table-wrapper">
          <div class="table-element">
            <table class="listData" v-if="!isLoading">
              <thead>
              <tr>
                <th :class="(sortBy === 'generals.id' ? 'active ' + sortDir : '')" @click="sortTable('generals.id')" class="id">
                  <div>ID</div>
                </th>
                <th :class="(sortBy === 'generals.module' ? 'active ' + sortDir : '')" @click="sortTable('generals.module')">
                  <div>Module</div>
                </th>
                <th :class="(sortBy === 'generals.key' ? 'active ' + sortDir : '')" @click="sortTable('generals.key')">
                  <div>Key</div>
                </th>
                <th :class="(sortBy === 'generals.info' ? 'active ' + sortDir : '')" @click="sortTable('generals.info')">
                  <div>Info</div>
                </th>
                <th :class="(sortBy === 'generals.created' ? 'active ' + sortDir : '')" @click="sortTable('generals.created')">
                  <div>Aangemaakt</div>
                </th>
              </tr>
              </thead>

              <tbody v-if="listData.length > 0">
              <tr @click="$router.push({name: 'admin.global', params: {module: listObj.module, key: listObj.key}})" :key="listObj.id" v-for="listObj in listData">
                <td class="id">{{ listObj.id }}</td>
                <td>{{ listObj.module }}</td>
                <td>{{ listObj.key }}</td>
                <td>{{ listObj.info }}</td>
                <td>{{ listObj.created.toFormat('dd/LL/yyyy') }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ total.min }} - {{ total.max }} van {{ listTotal }} globalen
            </div>

            <div class="buttons">
              <button class="btn-inverse" :disabled="page === 0" @click="prevPage()">Prev</button>
              <button class="btn-inverse" :disabled="listData.length !== perPage" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'
import { DateTime } from 'luxon'

export default {
  components: { PageTitle },
  data: function () {
    return {
      isLoading: true,
      search: '',
      listData: [],
      page: 0,
      perPage: 25,
      sortBy: 'created',
      sortDir: 'asc',
      listTotal: 0
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getListData()
    this.isLoading = false
  },
  methods: {
    async getListData () {
      await ApiService.fetchGlobals({
        page: this.page,
        sortBy: this.sortBy,
        sortDir: this.sortDir
      }).then(res => {
        this.listData = res.data.generals
        this.listData.map(obj => {
          obj.created = DateTime.fromISO(obj.created)
          return obj
        })
        this.listTotal = res.data.total
      })
    },
    async sortTable (sortBy) {
      if (sortBy === this.sortBy) {
        this.sortDir = (this.sortDir === 'asc' ? 'desc' : 'asc')
      }

      this.sortBy = sortBy

      await this.getListData()
    },
    prevPage: async function () {
      if (this.page > 0) {
        this.page--
        await this.getListData()
      }
    },
    nextPage: async function () {
      if (this.listData.length === this.perPage) {
        this.page++
        await this.getListData()
      }
    }
  },
  computed: {
    total: function () {
      const offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      return {
        min: offsetMin,
        max: offsetMax > this.listTotal ? this.listTotal : offsetMax
      }
    }
  }
}
</script>
